import axios from "../lib/axios";

const postLogin = async (body) => {
  const { data } = await axios.post(`/admin/login`, body);
  return data;
};

export const postForgetPassword = async (body) => {
  const { data } = await axios.post(`/admin/forgot-password`, body);
  return data;
};

export const postSetPassword = async (params) => {
  const { data } = await axios.post(`/admin/update-password`, {
    email: params.email,
    token: params.token,
    password: params.password,
    password_confirmation: params.password_confirmation,
    type: params.type,
  });
  return data;
};

export const getUserList = async (page, result, userType, filters, q) => {
  const { data } = await axios.get(`/admin/user`, {
    params: {
      page: page,
      per_page: result,
      user_type: userType,
      filters,
      q: q,
    },
  });
  return data;
};
export const loginAs = async (username) => {
  const { data } = await axios.get(`/admin/user/${username}/loginAs`);
  return data;
};

export const putUserEdit = async (username, body, type) => {
  const { data } = await axios.put(
    `/admin/user/${username}/update?user_type=${type}`,
    body
  );
  return data;
};

export const postUserCreate = async (body, type) => {
  const { data } = await axios.post(`/admin/user?user_type=${type}`, body);
  return data;
};

export const getUserDetail = async (username) => {
  const { data } = await axios.get(`/admin/user/${username}/show`, {
    params: {
      username: username,
    },
  });
  return data;
};

export const getResend = async (username) => {
  const { data } = await axios.get(`/admin/user/${username}/resend-email`);
  return data;
};

// ------------------- Start Access Level Api
export const getAccessLevels = async (type) => {
  const { data } = await axios.get(`/user/license_type/${type}/access_level`);
  return data;
};

// ------------------- Start Access Level Api
// ------------------- Start Customers Api
export const getCustomersList = async (params) => {
  console.log(
    "🚀 ~ file: api.js ~ line 54 ~ getCustomersList ~ params",
    params
  );
  const { data } = await axios.get(`admin/customers`, {
    params: {
      page: params?.current,
      paginated: params?.paginated,
      q: params?.q,
      status: params?.status,
      paginated: true,
    },
  });

  return await data;
};

export const getSupplier = async (customer_code) => {
  const { data } = await axios.get(`admin/customers/${customer_code}/supplier`);

  return await data;
};

export const getCustomersEdit = async (id) => {
  const { data } = await axios.get(`admin/customers/${id}/show`);

  return await data;
};

export const postCustomersCreate = async (body) => {
  const { data } = await axios.post(`admin/customers`, body);
  return await data;
};

export const putCustomersEdit = async (id, body) => {
  const { data } = await axios.put(`admin/customers/${id}`, body);
  return await data;
};
// ------------------- End Customers Api
// ------------------- Start Setting
export const getSettings = async (params) => {
  const { data } = await axios.get(`admin/settings`, {
    params: {
      type: params.type,
    },
  });

  return await data;
};
export const putSettings = async (params) => {
  const { type, ...body } = params;

  const { data } = await axios.put(`admin/settings/${type}`, body);

  return await data;
};

// ------------------- End Setting

// ------------------- Start Roles Api
export const getRoles = async (params) => {
  const { data } = await axios.get(`admin/roles`, {
    params: {
      licenece_type: params.licenece_type,
      page: params.current,
      paginated: params.paginated,
      q: params.q,
    },
  });
  return await data;
};

export const roleStore = async (body) => {
  const { data } = await axios.post(`admin/roles`, body);
  return await data;
};

export const roleUpdate = async (id, body) => {
  const { data } = await axios.put(`admin/roles/${id}`, body);
  return await data;
};

export const getRoleDetail = async (id) => {
  const { data } = await axios.get(`admin/roles/${id}/show`);
  return await data;
};

export const duplicateAccessLevel = async (id) => {
  const { data } = await axios.post(`admin/roles/${id}/duplicated`);
  return data;
};
// ------------------- End Roles Api
// ------------------- Start General Api

// ------------------- End General Api
export const getTerm = async () => {
  const { data } = await axios.get(`/term`);
  return await data;
};

export const getSetting = async () => {
  const { data } = await axios.get(`/setting`);
  return await data;
};
// ------------------- Start keywords Api
export const getAllergenKeywords = async (params) => {
  const { data } = await axios.get(`/admin/keywords`, {
    params: {
      paginated: true,
      per_page: params.pageSize,
      page: params.current,
      q: params.q,
    },
  });
  return await data;
};
export const getParentsAllergenKeywords = async () => {
  const { data } = await axios.get(`/admin/keywords`, {
    params: {
      parent: true,
    },
  });
  return await data;
};
export const putAllergenKeywords = async (id, body) => {
  const { data } = await axios.put(`admin/keywords/${id}`, body);
  return await data;
};
export const postAllergenKeywords = async (body) => {
  const { data } = await axios.post(`admin/keywords`, body);
  return await data;
};
export const getAllergenKeywordDetail = async (id) => {
  const { data } = await axios.get(`/admin/keywords/${id}/show`);
  return await data;
};
// ------------------- End recyclebin Api

// ------------------- Start log Api
export const getLog = async (params) => {
  const { data } = await axios.get(`/admin/auditlogs`, {
    params: {
      per_page: params.pageSize,
      page: params.current,
      paginated: true,
      from: params.from,
      to: params.to,
      entity: params.entity,
      action: params.action,
      q: params.q,
    },
  });
  return await data;
};
// ------------------- End log Api
export const getRecycleBin = async (params) => {
  // const { type, ...body } = params;
  let rote = params?.type;
  if (params?.type === "Supplier_user") {
    rote = "user";
  } else if (params?.type === "Customer_user") {
    rote = "user";
  } else if (params?.type === "Admin_user") {
    rote = "user";
  }
  const { data } = await axios.get(
    `/admin/${rote}/delete/soft?user_type=${params?.type}`,
    {
      params: {
        paginated: true,
        page: params.current,
        result: params.result,
        filters: params.filters,
        type: params.type,
        q: params.q,
      },
    }
  );
  return await data;
};
export const hardDelete = async (params) => {
  let rote = params?.type;
  if (params?.type === "Supplier_user") {
    rote = "user";
  } else if (params?.type === "Customer_user") {
    rote = "user";
  } else if (params?.type === "Admin_user") {
    rote = "user";
  }
  if (params.version) {
    const { data } = await axios.delete(
      `/admin/${params.type}/${params.id}/version/${params.version}/delete/hard`
    );
    return await data;
  } else {
    const { data } = await axios.delete(
      `/admin/${rote}/${params.id}/delete/hard`
    );
    return await data;
  }
};
export const softDelete = async (params) => {
  const { data } = await axios.delete(
    `/admin/${params.type}/${params.id}/soft`
  );
  return await data;
};
export const restore = async (params) => {
  let rote = params?.type;
  if (params?.type === "Supplier_user") {
    rote = "user";
  } else if (params?.type === "Customer_user") {
    rote = "user";
  } else if (params?.type === "Admin_user") {
    rote = "user";
  }
  if (params.version) {
    const { data } = await axios.put(
      `/admin/${params.type}/${params.id}/version/${params.version}/delete/soft/restore`
    );
    return await data;
  } else {
    const { data } = await axios.put(
      `/admin/${rote}/${params.id}/delete/soft/restore`
    );
    return await data;
  }
};
// ------------------- End recyclebin Api
// ------------------- End keywords Api
const getCols = async (module) => {
  const { data } = await axios.get(`${module}`);
  return await data;
};

const getRows = async (module, page, result, filters) => {
  const { data } = await axios.get(`${module}/data`, {
    params: {
      page: page,
      result: result,
      filters,
    },
  });
  return await data;
};

const getCreateFields = async (module) => {
  const { data } = await axios.get(`${module}/create`);
  return await data;
};

const getEditFields = async (module, id) => {
  const { data } = await axios.get(`${module}/${id}/edit`);
  return await data;
};

const postCreate = async (module, body) => {
  const { data } = await axios.post(`${module}`, body);
  return await data;
};

const postEdit = async (module, id, body) => {
  const { data } = await axios.put(`${module}/${id}`, body);
  return await data;
};

const getSelect = async (dataUrl, q) => {
  const { data } = await axios.get(`${dataUrl}&locale=all&search=${q}`);
  return await data;
};

const getSelectValue = async (dataUrl, id) => {
  const { data } = await axios.get(dataUrl, {
    params: {
      id: id,
    },
  });
  return await data;
};

const deleteRow = async (module, id) => {
  const { data } = await axios.delete(`${module}/${id}`);
  return await data;
};

export {
  postLogin,
  getCols,
  getRows,
  getCreateFields,
  getEditFields,
  postCreate,
  postEdit,
  getSelect,
  getSelectValue,
  deleteRow,
};
