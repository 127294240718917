import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import {
  Button,
  Card,
  Row,
  Table,
  Tag,
  Typography,
  Popover,
  notification,
  Space,
  Select,
} from "antd";
import {
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import * as api from "../../services/api";
import { useLocalStorage } from "../../hooks";

const { Title } = Typography;
const { Option } = Select;

function Users() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [accessLevels] = useLocalStorage("user");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Job title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Access Level",
      dataIndex: "roles_permissions",
      key: "roles_permissions",
      render: (roles_permissions) => roles_permissions.name,
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Link to={`/admin/admin_users/detail?username=${data.username}`}>
            View
          </Link>
          {accessLevels.user_soft_delete_admin && (
            <Button
              onClick={() => {
                api
                  .softDelete({ type: "user", id: data.username })
                  .then((res) => {
                    notification.success({
                      message: "Success",
                      description: "Item deleted successfully",
                    });
                    getData({
                      page: pagination.current,
                      result: pagination.pageSize,
                    });
                  });
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const getData = async ({
    page,
    result,
    userType = "Super_admin",
    filters = null,
  }) => {
    return api
      .getUserList(page, result, userType, filters)
      .then((res) => {
        setData(res.data);
        setPagination({
          current: res.meta.current_page,
          pageSize: res.meta.per_page,
          total: res.meta.total,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("getData -> err", err);
      });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getData({ page: pagination.current, result: pagination.pageSize });
    })();
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    // setPagination({
    //   ...pagination,
    //   current: newPagination.current,
    //   page: newPagination.page,
    //   total: newPagination.total,
    //   filters: filters,
    // });

    getData({
      page: newPagination.current,
      result: newPagination.pageSize,
      q: pagination.q,
    });
  };

  return (
    <>
      <h1>Users</h1>
      <div>
        <Row justify="space-between" align="bottom" className="mb-4">
          <Title className="capitalize">User</Title>

          {accessLevels.user_create_update_admin && (
            <Button type="primary">
              <Link to={`/admin/admin_users/detail`}>Add New</Link>
            </Button>
          )}
        </Row>
        <Card>
          <Table
            columns={columns}
            rowKey={(record) => record.email}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}

export default Users;
