import React, { useState, useCallback } from "react";
import { Upload, Button, Tooltip, Form } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { UploadOutlined } from "@ant-design/icons";

import Cookies from "js-cookie";

import Config from "../constants/config";

const type = "DragableUploadList";

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${
        isOver ? dropClassName : ""
      }`}
      style={{ cursor: "move" }}
    >
      {file.status === "error" ? errorNode : originNode}
    </div>
  );
};

const DragSortingUpload = (props) => {
  const initialValueHandeling = (data) => {
    let newData = [];

    if (data === null) {
      return null;
    }

    if (!Array.isArray(data)) {
      newData.push({
        uid: 1,
        name: data,
        value: `${data}`,
        url: `${data}`,
      });
    } else {
      newData = data.map((item, index) => ({
        uid: index,
        name: item,
        value: `${item}`,
        url: `${Config.storage}/${item}`,
      }));
    }
    return newData;
  };

  const [fileList, setFileList] = useState(initialValueHandeling(props.value));

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      props.onChange(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    props.onChange(newFileList);
  };
  const token = Cookies.get("api_token");
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Upload
          action={`${Config.apiBaseUrl}/${props.action}`}
          headers={{ Authorization: `Bearer ${token}` }}
          // defaultFileList={initialValueHandeling(props.value)}
          fileList={fileList}
          listType="picture"
          maxCount={props.maxCount}
          onChange={onChange}
          disabled={props.readonly}
          className={props.readonly && "readOnly"}
          //   {...props}
          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </DndProvider>
    </>
  );
};

const CustomUpload = (props) => {
  const normFile = (e) => {
    // return e.fileList
    if (e.length === 1) {
      if (e[0].response !== undefined) {
        return `${e[0].response.data.file}`;
      }
      if (e[0].value !== undefined) {
        return `${e[0].value}`;
      }
    }
    return e.map((item) => {
      if (item.response !== undefined) {
        return `${item.response.data.file}`;
      }
      if (item.value !== undefined) {
        return `${item.value}`;
      }
    });
  };

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      // valuePropName="fileList"
      initialValue={props.value}
      rules={props.rules}
      getValueFromEvent={normFile}
      // setFieldsValue={fileList}
    >
      <DragSortingUpload {...props} />
    </Form.Item>
  );
};
export default CustomUpload;
