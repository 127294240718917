import { useState } from "react";

import { useHistory } from "react-router-dom";

import { Form, Input, Button, notification } from "antd";

import { LockOutlined } from "@ant-design/icons";

import * as api from "../../services/api";
import { useUrlParams } from "../../hooks";

const SetPassword = () => {
  const [urlParams] = useUrlParams();

  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    api
      .postSetPassword({
        email: urlParams.email,
        token: urlParams.token,
        password: values.password,
        password_confirmation: values.password_confirmation,
        type: urlParams.type || null,
      })
      .then((res) => {
        setLoading(false);
        notification["success"]({
          message: "Password changed successfully",
        });
        login(res.data.api_token);
      })
      .catch((err) => {
        setLoading(false);
        notification["error"]({
          message: "problem",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const login = (token) => {
    history.push("/admin/auth/login");
  };

  return (
    <>
      <div className="w-full max-w-sm m-auto flex-grow flex-col flex justify-center">
        <Form
          name="basic"
          className="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="password confirmation"
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full"
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>

      <p className="my-7 text-center login-footer">
        -
        <br />
        Copyright ©2021 Produced by - Finance Experience Technology Department
      </p>
    </>
  );
};

export default SetPassword;
