import React, { useState, useEffect, useRef } from "react";

import { Card, Table, Typography, Select, Input, DatePicker } from "antd";

import * as api from "../../services/api";

const { Search } = Input;
const { Title } = Typography;

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [customers, setCustomers] = useState();
  const searchInput = useRef(null);

  const [pagination, setPagination] = useState({
    current: 1,
    paginated: true,
    pageSize: 15,
    total: 0,
  });

  const handleSearch = (value) => {
    setPagination({
      ...pagination,
      from: value[0],
      to: value[1],
    });
  };

  const getColumnSearchProps = () => ({
    filterDropdown: () => (
      <DatePicker.RangePicker
        ref={searchInput}
        format="DD/MM/YYYY"
        onChange={(e, val) => {
          handleSearch(val);
        }}
      />
    ),
  });

  const columns = [
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      render: (detail) => (
        <div dangerouslySetInnerHTML={{ __html: detail }}></div>
      ),
    },
    {
      title: "Date and Time",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "customer name",
      dataIndex: "customer_name",
      key: "customer_name",
      // filters: customers?.data,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      filters: [
        {
          text: "create",
          value: "create",
        },
        {
          text: "update",
          value: "update",
        },
        {
          text: "submit",
          value: "submit",
        },
        {
          text: "delete",
          value: "delete",
        },
        {
          text: "trash",
          value: "trash",
        },
        {
          text: "restore",
          value: "restore",
        },
        {
          text: "system",
          value: "system",
        },
        {
          text: "login",
          value: "login",
        },
        {
          text: "accept",
          value: "accept",
        },
      ],
    },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
      filters: [
        {
          text: "Role",
          value: "Role",
        },
        {
          text: "Policy",
          value: "Policy",
        },
        {
          text: "User",
          value: "User",
        },
        {
          text: "Certificate",
          value: "Certificate",
        },
        {
          text: "Manufacture",
          value: "Manufacture",
        },
        {
          text: "Supplier",
          value: "Supplier",
        },
        {
          text: "Customer",
          value: "Customer",
        },
        {
          text: "Allerge",
          value: "Allerge",
        },
      ],
    },
  ];

  const getData = async () => {
    setLoading(true);
    return api.getLog(pagination).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  // useEffect(() => {
  //   getCustomersList();
  // }, [pagination]);

  useEffect(() => {
    getData();
  }, [pagination]);

  const handleTableChange = (newPagination, filters) => {
    console.log(
      "🚀 ~ file: log.js:177 ~ handleTableChange ~ newPagination",
      newPagination
    );
    setPagination({
      ...pagination,
      pageSize: newPagination.pageSize,
      current: newPagination.current,
      page: newPagination.current,
      action: filters.action,
      entity: filters.entity,
    });
  };

  const onSearch = (value) => {
    setPagination({
      ...pagination,
      page: 1,
      q: value,
    });
  };

  return (
    <>
      <Search
        className="top-page-search"
        placeholder="input search text"
        onSearch={onSearch}
        enterButton
      />

      <h1>Logs</h1>
      <div>
        <Title className="capitalize">Logs</Title>
        <Card>
          <Table
            columns={columns}
            rowKey={(record) => record.email}
            dataSource={data?.data}
            pagination={{
              current: pagination?.current,
              total: data?.meta?.total,
            }}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}
