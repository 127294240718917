import { useState } from "react";

import { useHistory } from "react-router-dom";

import { Form, Input, Button, notification } from "antd";

import { UserOutlined } from "@ant-design/icons";

import * as api from "../../services/api";

const Login = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    api
      .postForgetPassword(values)
      .then((res) => {
        setLoading(false);
        notification["success"]({
          message: "Password recovery email has been sent to you",
        });
        history.push("/admin/login");
      })
      .catch((err) => {
        setLoading(false);
        notification["error"]({
          message: "problem",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="w-full max-w-sm m-auto flex-grow flex-col flex justify-center">
        <Form
          name="basic"
          className="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input placeholder="Email" prefix={<UserOutlined />} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full"
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>

      <p className="my-7 text-center login-footer">
        -
        <br />
        Copyright ©2021 Produced by - Finance Experience Technology Department
      </p>
    </>
  );
};

export default Login;
