import { Button, Space } from "antd";

export default function Index({ loading, editMode }) {
  return (
    <>
      {editMode && (
        <Space className="justify-end flex mt-2">
          {/* <Button onClick={goBack}>Cancel</Button> */}
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Space>
      )}
    </>
  );
}
