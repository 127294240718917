import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";

import { Link } from "react-router-dom";

import { PieChartOutlined } from "@ant-design/icons";
import { useLocalStorage } from "../hooks";

const { Sider } = Layout;

function App() {
  const [current, setCurrent] = useState("Dashboard");
  const [accessLevels] = useLocalStorage("user");

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Sider className="overflow-auto h-screen fixed left-0">
      <div className="logo text-xl text-white p-4 bg-black">ADMIN PANEL</div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["dashboard"]}
        onClick={handleClick}
        selectedKeys={[current]}
        mode="inline"
      >
        {/* <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
          <Link to="/admin/dashboard">Dashboard</Link>
        </Menu.Item> */}
        {accessLevels?.customer_index_single && (
          <Menu.Item key="customers" icon={<PieChartOutlined />}>
            <Link to="/admin/customers">Customers</Link>
          </Menu.Item>
        )}

        {/* <Menu.Item key="security" icon={<PieChartOutlined />}>
          <Link to="/admin/security">Security</Link>
        </Menu.Item> */}
        {(accessLevels?.user_index_single_customer ||
          accessLevels?.user_index_single_supplier) && (
          <Menu.Item key="users" icon={<PieChartOutlined />}>
            <Link to="/admin/users">Users</Link>
          </Menu.Item>
        )}
        {accessLevels?.user_index_single_admin && (
          <Menu.Item key="admin_users" icon={<PieChartOutlined />}>
            <Link to="/admin/admin_users">Admin Users</Link>
          </Menu.Item>
        )}
        {/* <SubMenu
          key="configurations"
          icon={<PieChartOutlined />}
          title="Configurations"
        > */}
        {accessLevels?.role_index_single && (
          <Menu.Item key="access-level" icon={<PieChartOutlined />}>
            <Link to="/admin/access-level">Access Level</Link>
          </Menu.Item>
        )}
        {accessLevels?.setting_index_single && (
          <Menu.Item key="terms" icon={<PieChartOutlined />}>
            <Link to="/admin/term">Terms of use</Link>
          </Menu.Item>
        )}

        {accessLevels?.audit_log_index_show && (
          <Menu.Item key="logs" icon={<PieChartOutlined />}>
            <Link to="/admin/log">Logs</Link>
          </Menu.Item>
        )}

        {(accessLevels?.certificate_hard_delete_hard_restore_recycleBin_admin ||
          accessLevels?.customer_soft_delete_restore_recycleBin_admin ||
          accessLevels?.manufacture_hard_delete_hard_restore_recycleBin_admin ||
          accessLevels?.policy_hard_delete_hard_restore_recycleBin_admin ||
          accessLevels?.product_hard_delete_hard_restore_recycleBin_admin ||
          accessLevels?.role_hard_delete_hard_restore_recycleBin_admin ||
          accessLevels?.supplier_hard_delete_hard_restore_recycleBin_admin ||
          accessLevels?.user_hard_delete_hard_restore_recycleBin_admin) && (
          <Menu.Item key="recycle-bin" icon={<PieChartOutlined />}>
            <Link to="/admin/recycle-bin">Recycle Bin</Link>
          </Menu.Item>
        )}

        {accessLevels?.keyword_index_single && (
          <Menu.Item key="allergen" icon={<PieChartOutlined />}>
            <Link to="/admin/allergen-keywords">Allergen Keywords</Link>
          </Menu.Item>
        )}

        {accessLevels?.setting_index_single && (
          <Menu.Item key="setting" icon={<PieChartOutlined />}>
            <Link to="/admin/setting">Setting</Link>
          </Menu.Item>
        )}

        {/* </SubMenu> */}
      </Menu>
    </Sider>
  );
}

export default App;
