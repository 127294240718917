import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  notification,
  Typography,
  Card,
  Row,
  Select,
  Col,
  Switch,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage, useUrlParams } from "../../hooks";

import Save from "../../components/Save";

import { mapErrors } from "../../lib/helpers";

const { Title } = Typography;
const { Option } = Select;

export default function Index() {
  const [accessLevels] = useLocalStorage("user");

  const [urlParams, , setUrlParams] = useUrlParams();
  const [initialValue, setInitialValue] = useState([]);
  const [parent, setParent] = useState([]);
  const [loading, setLoading] = useState(!!urlParams.id);
  const [loadingGetParent, setLoadingGetParent] = useState(!!urlParams.id);
  const [redirecting, setRedirecting] = useState(false);

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const getData = () => {
    setLoading(true);
    api
      .getAllergenKeywordDetail(urlParams.id)
      .then((res) => {
        setInitialValue(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoadingGetParent(true);
    api
      .getParentsAllergenKeywords()
      .then((res) => {
        setParent(res.data);
        setLoadingGetParent(false);
      })
      .catch(() => {
        setLoadingGetParent(false);
      });

    setInitialValue([]);
    async function makePage() {
      await getData();
    }
    if (urlParams.id) {
      makePage();
    }
  }, [urlParams.id]);

  const onFinish = (values) => {
    setLoading(true);
    if (urlParams.id) {
      api
        .putAllergenKeywords(urlParams.id, values)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      api
        .postAllergenKeywords(values)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
          history.replace(`/admin/allergen-keywords/detail?id=${res.data.id}`);
        })
        .catch((err) => {
          setLoading(false);
          // notification["error"]({
          //   message: mapErrors(err.response.data.message),
          // });
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log("🚀 ~ file: Create.js ~ line 96 ~ Create ~ redirecting", redirecting)

  if (redirecting) {
    return <Redirect to={redirecting} />;
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">Keywords </Breadcrumb.Item>
        <Breadcrumb.Item className="capitalize">detail</Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">Keywords </Title>
        </Col>
        {accessLevels.keyword_create_update && (
          <Col>
            {urlParams.editMode ? (
              <Button onClick={() => setUrlParams({ editMode: false })}>
                Cancel
              </Button>
            ) : (
              <Button onClick={() => setUrlParams({ editMode: true })}>
                Edit
              </Button>
            )}
          </Col>
        )}
      </Row>

      <Form
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card loading={loading || loadingGetParent}>
          {[
            {
              displayText: "name",
              required: true,
              key: "name",
            },
            {
              displayText: "render",
              required: true,
              key: "render",
            },
            {
              displayText: "parent render",
              required: true,
              key: "parent_render",
            },
            // {
            //   displayText: "is exception",
            //   required: true,
            //   key: "is_exception",
            // },
            // {
            //   displayText: "Customer Status",
            //   required: true,
            //   key: "customer_status",
            // },
          ].map((el) => (
            <Form.Item
              label={el.displayText}
              name={el.key}
              initialValue={initialValue[el.key]}
              rules={[{ required: el.required }]}
            >
              <Input disabled={!urlParams?.editMode} />
            </Form.Item>
          ))}

          <Form.Item
            label="parent"
            name="parent_id"
            initialValue={initialValue.parent_id}
            rules={[{ required: true }]}
          >
            <Select disabled={!urlParams?.editMode}>
              {parent?.map((el) => (
                <Option value={el.id}>{el.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Is Exception"
            name="is_exception"
            initialValue={initialValue.is_exception}
            valuePropName="checked"
          >
            <Switch disabled={!urlParams?.editMode} />
          </Form.Item>
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </div>
  );
}
