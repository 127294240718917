import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import {
  Button,
  Card,
  Row,
  Table,
  Tag,
  Typography,
  Popover,
  notification,
  Space,
  Input,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage } from "../../hooks";

const { Title } = Typography;

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [accessLevels] = useLocalStorage("user");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "render",
      dataIndex: "render",
      key: "render",
    },
    {
      title: "parent render",
      dataIndex: "parent_render",
      key: "parent_render",
    },
    {
      title: "is exception",
      dataIndex: "is_exception",
      key: "is_exception",
      render: (is_exception) => <>{is_exception ? "Yes" : "No"}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Link to={`/admin/allergen-keywords/detail?id=${data.id}`}>View</Link>

          {accessLevels.keyword_hard_delete && (
            <Button
              onClick={() => {
                api
                  .hardDelete({
                    type: "keywords",
                    id: data.id,
                  })
                  .then((res) => {
                    notification.success({
                      message: "Success",
                      description: "Item deleted successfully",
                    });
                    getData();
                  });
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const getData = async () => {
    return api.getAllergenKeywords(pagination).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [pagination]);

  const handleTableChange = (TablePagination, filters, sorter) => {
    setPagination({
      ...pagination,
      current: TablePagination.current,
      pageSize: TablePagination.pageSize,
    });
  };

  return (
    <>
      <Input.Search
        className="top-page-search"
        placeholder="input search text"
        onSearch={(value) => {
          setPagination({
            ...pagination,
            current: 1,
            q: value,
          });
        }}
        enterButton
      />
      <h1>Allergen keywords</h1>
      <div>
        <Row justify="space-between" align="bottom" className="mb-4">
          <Title className="capitalize">Allergen keywords</Title>
          <Button type="primary">
            {accessLevels.keyword_create_update && (
              <Link to={`/admin/allergen-keywords/detail?editMode=true`}>
                Add New
              </Link>
            )}
          </Button>
        </Row>
        <Card>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data?.data}
            pagination={{
              current: pagination?.current,
              total: data?.meta?.total,
            }}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}
