import { useEffect, useState, useMemo } from "react";
import {
  Link,
  Redirect,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  Form,
  Spin,
  Input,
  Button,
  Breadcrumb,
  notification,
  Typography,
  Card,
  Row,
  Space,
  Select,
  Col,
  Switch,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage, useUrlParams } from "../../hooks";

import Save from "../../components/Save";

import { mapErrors } from "../../lib/helpers";

const { Title } = Typography;
const { Option } = Select;

export default function Index() {
  const [urlParams, , setUrlParams] = useUrlParams();
  const [initialValue, setInitialValue] = useState([]);
  const [loading, setLoading] = useState(!!urlParams.username);
  const [accessLevelsLoading, setAccessLevelsLoading] = useState(
    !!urlParams.username
  );
  const [roles, setRoles] = useState();
  const [customersList, setCustomersList] = useState();
  const [redirecting, setRedirecting] = useState(false);
  const [accessLevels] = useLocalStorage("user");

  const history = useHistory();
  const [form] = Form.useForm();

  const goBack = () => {
    history.goBack();
  };

  const getData = () => {
    setLoading(true);
    api
      .getUserDetail(urlParams.username)
      .then((res) => {
        setInitialValue(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getAccessLevelsList = () => {
    setAccessLevelsLoading(true);
    api
      .getRoles({ licenece_type: "system_admin" })
      .then((res) => {
        setRoles(res.data);
        setAccessLevelsLoading(false);
      })
      .catch(() => {
        console.log("getData -> loading", loading);
      });
  };

  useEffect(() => {
    setInitialValue([]);
    async function makePage() {
      await getData();
    }
    async function makeAccessLevelsList() {
      await getAccessLevelsList();
    }
    if (urlParams.username) {
      makePage();
    }
    makeAccessLevelsList();
  }, [urlParams.username]);

  const onFinish = (values) => {
    values.license_type = {
      code: "system_admin",
      name: "Administrator License",
    };

    setLoading(true);
    if (urlParams.username) {
      api
        .putUserEdit(urlParams.username, values, "Super_admin")
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      api
        .postUserCreate(values, "Super_admin")
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
          history.replace(
            `/admin/admin_users/detail?username=${res.data.username}`
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (redirecting) {
    return <Redirect to={redirecting} />;
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">Admin User </Breadcrumb.Item>
        <Breadcrumb.Item className="capitalize">detail</Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">Admin User </Title>
        </Col>
        {/* {accessLevels.role_create_update && ( */}
        <Col>
          {urlParams.editMode ? (
            <Button
              onClick={() => setUrlParams({ editMode: false })}
              loading={loading}
            >
              Cancel
            </Button>
          ) : (
            <Button
              onClick={() => setUrlParams({ editMode: true })}
              loading={loading}
            >
              Edit
            </Button>
          )}
        </Col>
        {/* )} */}
      </Row>

      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card loading={!loading && !accessLevelsLoading ? false : true}>
          {[
            {
              displayText: "Full Name",
              key: "name",
              required: true,
              type: "text",
            },
            {
              displayText: "Email",
              key: "email",
              required: true,
              type: "email",
            },
            {
              displayText: "Job Title",
              key: "job_title",
              required: true,
              type: "text",
            },
            {
              displayText: "Contact Number",
              key: "contact_number",
              required: true,
              disabled: false,
              type: "text",
            },
            {
              displayText: "Commercial Contact",
              key: "is_commercial_contact",
              disabled: false,
              type: "switch",
            },
          ].map((el) => (
            <div key={el.key}>
              {el.type === "switch" ? (
                <Form.Item
                  label={el.displayText}
                  name={el.key}
                  initialValue={initialValue[el.key] ?? false}
                  valuePropName="checked"
                  rules={[{ required: el.required }]}
                >
                  <Switch className="mr-4" disabled={!urlParams?.editMode} />
                </Form.Item>
              ) : (
                <Form.Item
                  label={el.displayText}
                  name={el.key}
                  key={el.key}
                  initialValue={initialValue[el.key]}
                  rules={[{ required: el.required }]}
                >
                  <Input disabled={!urlParams?.editMode} />
                </Form.Item>
              )}
            </div>
          ))}
          <Form.Item
            label="license type"
            name="license_type"
            initialValue={initialValue?.license_type?.code}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // showSearch
              disabled={!urlParams?.editMode}
            >
              <Option value="system_admin">Administrator License</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Access Level"
            name="access_level"
            initialValue={initialValue?.roles_permissions?.code}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled={!urlParams?.editMode}>
              {roles?.map((el) => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </div>
  );
}
