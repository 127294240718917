import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers/Customers";
import CustomersDetail from "./pages/Customers/CustomersDetail";
import Security from "./pages/Security";
import Users from "./pages/Users/Users";
import RecycleBin from "./pages/recycleBin/recycleBin";
import UserDetail from "./pages/Users/UserDetail";
import AccessLevels from "./pages/AccessLevel/AccessLevels";
import AccessLevelDetail from "./pages/AccessLevel/AccessLevelDetail";
import Term from "./pages/Configurations/Term";
import AllergenKeywords from "./pages/Configurations/AllergenKeywords";
import AllergenKeywordDetail from "./pages/Configurations/AllergenKeywordDetail";
import Setting from "./pages/Configurations/Setting";
import Log from "./pages/log/log";

import AdminUsers from "./pages/AdminUsers/AdminUsers";
import AdminUsersDetail from "./pages/AdminUsers/AdminUsersDetail";

import Login from "./pages/auth/Login";
import SetPassword from "./pages/auth/SetPassword";
import ForgetPassword from "./pages/auth/ForgetPassword";

const dashboardRoutes = [
  {
    path: "/admin_users/detail",
    name: "users",
    component: AdminUsersDetail,
    layout: "/admin",
  },
  {
    path: "/admin_users",
    name: "admin_users",
    component: AdminUsers,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/customers/detail",
    name: "customers-detail",
    component: CustomersDetail,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "customers",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/security",
    name: "security",
    component: Security,
    layout: "/admin",
  },
  {
    path: "/users/detail",
    name: "users",
    component: UserDetail,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/access-level/detail",
    name: "access-level-detail",
    component: AccessLevelDetail,
    layout: "/admin",
  },
  {
    path: "/access-level",
    name: "access-level",
    component: AccessLevels,
    layout: "/admin",
  },
  {
    path: "/term",
    name: "term",
    component: Term,
    layout: "/admin",
  },
  {
    path: "/allergen-keywords/detail",
    name: "allergen-keyword-detail",
    component: AllergenKeywordDetail,
    layout: "/admin",
  },
  {
    path: "/allergen-keywords",
    name: "allergen-keywords",
    component: AllergenKeywords,
    layout: "/admin",
  },
  {
    path: "/recycle-bin",
    name: "recycle-bin",
    component: RecycleBin,
    layout: "/admin",
  },
  {
    path: "/log",
    name: "log",
    component: Log,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "setting",
    component: Setting,
    layout: "/admin",
  },
];
const publicRoutes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forget-password",
    name: "users",
    component: ForgetPassword,
    layout: "/auth",
  },
  {
    path: "/reset-password",
    name: "users",
    component: SetPassword,
    layout: "/auth",
  },
];

export { dashboardRoutes, publicRoutes };
