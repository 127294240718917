import Cookies from "js-cookie";
import * as DateUtils from "date-fns";

import config from "../constants/config";
import { notification } from "antd";

const errorHandler = (error) => {
  if (config.isDevEnv && error) {
    console.log("onError", {
      url: error?.config?.url,
      error,
      config: error?.config,
      response: error?.response,
    });

    try {
      console.log("onError", {
        ...(error?.config?.data && { data: JSON.parse(error?.config?.data) }),
      });
    } catch (e) {}
  }

  if (error.response?.status === 401) {
    Cookies.remove("token");

    if (window.location.pathname !== "/admin/auth/login")
      window.location.replace("/admin/auth/login");
  }

  if (error.response?.status === 403) {
    notification["error"]({
      message: "This action is unauthorized",
    });
    return;
  }

  if (error.response?.status === 404) {
    notification["error"]({
      message: "The requested page could not be found",
    });
    return;
  }

  if (error.response?.data?.data?.errors) {
    const errorArr = Object.values(error.response.data.data.errors).flat();

    errorArr.forEach((errMsg) => {
      notification["error"]({
        message: errMsg,
      });
    });
  } else if (error.response?.data?.data?.status === "failed") {
    // without the roast id , toast gets shoot twice for some reason
    notification["error"]({
      message: String(error.response?.data?.data?.message),
    });
  } else {
    // without the roast id , toast gets shoot twice for some reason
    notification["error"]({
      message: String(error.response?.data.timeStamp),
    });
  }

  if (!error.response && error.isAxiosError && error.message) {
    notification["error"]({
      message: error.message,
    });
  }
};

const convertToIso = (date) => {
  const isFormatDesired = DateUtils.isMatch(date, "dd/MM/yyyy");

  if (isFormatDesired) return DateUtils.parse(date, "dd/MM/yyyy", new Date());

  return new Date(date);
};

function formatDate(date, format?) {
  if (!date) return "";

  try {
    const validDate = typeof date === "string" ? convertToIso(date) : date;

    return DateUtils.format(validDate, format || "dd/MM/yyyy");
  } catch (error) {
    return "";
  }
}

export { formatDate, convertToIso, errorHandler };
