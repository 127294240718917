import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { publicRoutes } from "../routes.js";

const switchRoutes = (
  <Switch>
    {publicRoutes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={"/admin/auth" + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
  </Switch>
);

function PublicLayout(props) {
  return (
    <div className="h-screen flex items-center flex-col bg-contain bg-center">
      {switchRoutes}
    </div>
  );
}

export default PublicLayout;
